@charset "utf-8";/* CSS Document */
*{margin: 0;padding: 0;}
html {font-size: 62.5%;height: 100%}
body {margin: 0 auto;font-family:Arial, Helvetica, sans-serif;font-size: 14px;font-size: 1.4rem;height: 100%;overflow-x: hidden;max-width: 1920px;}
img {border: none;}
ul li {list-style-type: none;}
ul, form, p, a, img, table, tr, td, li, dd, dt, dl, span {margin: 0;padding: 0;list-style: none;color: #333;}
a {text-decoration: none;color: #333;outline: none;transition: 0.3s;-moz-transition: 0.3s;-o-transition: 0.3s;-webkit-transition: 0.3s;}
h1 {margin: 0;padding: 0;font-weight: normal;color: #333;}
.clear {clear: both;}
.maln {margin-left: auto !important}
input, textarea {font-family:Arial, Helvetica, sans-serif;font-size: 14px;font-size: 1.4rem;color:#333;border: none;outline: none;}
.l{ float:left;}
.r{ float:right;}
.fix{*zoom:1; } .fix:after,.fix:before{display:block; content:"clear"; height:0; clear:both; overflow:hidden; visibility:hidden; }

::-webkit-input-placeholder {/* WebKit browsers */
 color:#333333;}
:-o-placeholder {/* Mozilla Firefox 4 to 18 */
 color:#333333;}
::-moz-placeholder {/* Mozilla Firefox 19+ */
 color:#333333; opacity: 1;}
:-ms-input-placeholder {/* Internet Explorer 10+ */
 color:#333333;}



.search ::-webkit-input-placeholder {/* WebKit browsers */
color:#a5a5a5;}
.search :-o-placeholder {/* Mozilla Firefox 4 to 18 */
color:#a5a5a5;}
.search ::-moz-placeholder {/* Mozilla Firefox 19+ */
color:#a5a5a5; opacity: 1;}
.search :-ms-input-placeholder {/* Internet Explorer 10+ */
color:#a5a5a5;}
 
.wp {width: 1200px;margin: auto;}
@media screen and (min-width:1220px) {.wp {width: 1200px;margin: auto;}}
@media screen and (min-width:1000px) and (max-width:1219px) {.wp {width: 980px;margin: auto;}}
@media screen and (max-width:999px) {.wp {width: 95%;margin: auto;}}

.no_margin{margin-left: auto!important\0;}
@media screen and (min-width:1220px) {.no_margin{margin-left: auto!important;}}
@media screen and (min-width:1000px) and (max-width:1219px) {.no_margin{margin-left: auto!important;}}

/*.container*/
.container { width: 5px; height: 100%; position: absolute; right: 0; -webkit-transition: all 0.4s ease 0s; transition: all 0.4s ease 0s; z-index: 999999999999999; cursor: pointer; position: fixed; top: -5px; right: 0; display: block; }

.container .nav { overflow: hidden; width: 300px; background: rgba(0, 0, 0, 0.8); position: absolute; top: 0; right: 0; display: block; height: auto; overflow: scroll; }

.container .nav form { margin: 15px auto; width: 258px; height: 26px; border: 1px solid #fff; }

.container .nav form .iptc { box-sizing: border-box; padding-left: 15px; background-color: #fff; width: 80%; height: 26px; line-height: 26px; display: block; float: left; }

.container .nav form .ipsc { width: 20%; height: 26px; line-height: 26px; display: block; float: right; background: #fff url(../images/sousuo2.png) no-repeat center center; }

.container .nav > ul { display: none; margin-top: 0px; margin-bottom: 0px; overflow: scroll; padding-top:45px;-ms-box-sizing: border-box;-o-box-sizing: border-box;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}

.container .nav > ul > li { line-height: 40px; font-size: 12px; font-size: 1.2rem; text-indent: 20px; position: relative; }

.container .nav > ul > li .addition { width: 20px; height: 20px; position: absolute; right: 15px; top: 10px; background: url("fonts/shizi.png") no-repeat center center;  }

.container .nav > ul > li .addition.fa:before { margin-right: 5px; color: #fff; text-indent: 0; }

.container .nav > ul > li > a { color: #fff; font-size: 12px; font-size: 1.2rem; display: block; margin-right: 40px;}

.container .nav > ul > li > a span { color: #fff; font-size: 12px; font-size: 1.2rem; }

.container .nav > ul > li .c-show { display: none; width: 100%; }


.container .nav > ul > li .c-show1 .c-s1 { position: relative; padding-left: 15px;}

.container .nav > ul > li .c-show1 .c-s1 > a {position: relative;  font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; margin-right: 40px;}

.container .nav > ul > li .c-show1 .c-s1 > a span { font-size: 12px; font-size: 1.2rem; color: #fff; }


.container .nav > ul > li .c-show1 .c-s1 > .c-show2 {position: relative;}

.container .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2{position: relative; }

.container .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 > a { text-indent: 35px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; margin-right: 40px;}

.container .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 > a  span { font-size: 12px; font-size: 1.2rem; color: #fff; }


.container .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 {position: relative;}

.container .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 .c-s3 a { text-indent: 50px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; }

.container .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 .c-s3 a span { font-size: 12px; font-size: 1.2rem; color: #fff; }


.container .nav > ul > dl{padding: 20px;}

.container .nav > ul > dl dd{float: left;margin: 0 2px;}

.container .menubtn { width: 23px; height: 18px; position: absolute; top: 12px; right: 12px; -webkit-transition: all 0.4s ease 0s; transition: all 0.4s ease 0s; z-index: 9999; }

.container .menubtn span { display: block; width: 100%; height: 3px; margin-bottom: 3px; background-color: #000; }

@media screen and (min-width: 768px) { .container, .nav { display: none!important; } }


/***********  lunbo  *************/
*{margin: 0;padding:0;}
li{list-style: none;}

.slide { position: relative;}

.slide .carouse { width: 100%; overflow: hidden; position: relative; }

.slide .carouse .slideItem { width: 100%; position: absolute; cursor: pointer; }

.slide .carouse .slideItem .banner-img { width: 100%;display: block; }

.slide .console { height: 57px; display: inline-block; width: 35px; position: absolute; top: 40%; }

.slide .dotList { position: absolute; width: 100%; bottom: 12%; text-align: center; }

.slide .dotList2{bottom: 20px;}

.slide .dotList ul li { display: inline-block; width: 14px; height: 14px; background:#fff; margin: 0 8px; cursor: pointer; }

.slide .carousel-control { position: absolute; top: 50%; width: 28px; height: 52px; z-index: 999; margin-top: -26px;}

.slide .carousel-control.left { left: 5%; background-image: url("../images/prev.png"); background-repeat: no-repeat; cursor: pointer; }

.slide .carousel-control.left:hover { background-image: url("../images/prev2.png"); }

.slide .carousel-control.right { right: 5%; background-image: url("../images/next.png"); background-repeat: no-repeat; cursor: pointer; }

.slide .carousel-control.right:hover { background-image: url("../images/next2.png"); }

.slide .dotList ul li{cursor: pointer; background:url(../images/dian1.png) no-repeat center center;  font-size: 0px;   margin-left: 3px;margin-right: 3px; border: none;outline: none;width: 11px;height:11px;}
.slide .dotList ul li.active{background:url(../images/dian2.png) no-repeat center center;}

.slide .slidedetail { display: none; }

@media screen and (max-width:999px) {
    .slide .carousel-control{display: none;}
    .slide .dotList{bottom: 15px;}
}

/***********  head  ************/
@font-face { font-family: 'OPENSANSBOLD_0'; src: url("../fonts/OPENSANSBOLD_0.eot"); src: url("../fonts/OPENSANSBOLD_0.eot") format("embedded-opentype"), url("../fonts/OPENSANSBOLD_0.woff2") format("woff2"), url("../fonts/OPENSANSBOLD_0.woff") format("woff"), url("../fonts/OPENSANSBOLD_0.ttf") format("truetype"), url("../fonts/OPENSANSBOLD_0.svg#OPENSANSBOLD_0") format("svg"); }

.headbox{position: absolute;left: 0;top: 0;width: 100%;z-index: 99999;}
.head{background: url(../images/headbg.png);}

.topli{padding-left: 29px;background: url(../images/topi1.png) no-repeat left center;color: #fff;font-size:16px;font-size:1.6rem;margin-top: 26px;margin-right: 60px;}
.topli a{color: #fff;}

.logo{padding-top: 25px;}
.logo img{max-width: 100%;}
.navlist{padding-top: 26px;}
.navlist2{display: none;}
.navlist>li{float: left;margin-left: 121px;position: relative;padding-bottom: 25px;}
.navlist>li>a{font-size: 18px;font-size: 1.8rem;text-transform: uppercase;display: block;padding-bottom: 3px;color: #fff;font-family: 'OPENSANSBOLD_0';border-bottom: 3px solid transparent;}
.navlist>li:hover>a{border-bottom: 3px solid #fff;}
.navlist>li.currentnav>a{border-bottom: 3px solid #fff;}

.search{margin-top: 8px;position: relative;}
.search>img{cursor: pointer;}
.sou{width: 218px;height: 34px;line-height: 34px;padding-left: 2px;border-bottom: 1px solid #fff;font-size:16px;font-size:1.6rem;color: #a5a5a5;background: transparent;}
.suo{position: absolute;right: 6px;top: 4px;width: 26px;height: 26px;background: url(../images/sousuo.png) no-repeat center center;cursor: pointer;}

@media screen and (max-width:1219px) {
    .navlist > li{margin-left: 60px;}
}
@media screen and (max-width:999px) {
    .headbox{position: static;}
    .navlist > li{margin-left: 30px;}
}
@media screen and (max-width:900px) {
    .topli{margin-right: 25px;margin-top: 20px;}
    .navlist > li{padding-bottom: 15px;}
    .navlist > li > a{font-size:16px;font-size:1.6rem;}
    .logo{padding-top: 15px;width: 250px;}
}
@media screen and (max-width:810px) {
    .navlist > li{margin-left: 23px;}
    .topli{margin-right: 20px;}
}
@media screen and (max-width:767px) {
    .navlist2{display: block;}
    .navlist1{display: none;}
    .navlist{width: 100%;}
    .headyou{width: 100%;}
    .navlist>li{width: 25%;margin-left: 0;text-align: center;}
    .topli{display: none;}
    .search{width: 100%;}
    .sou{width: calc(100% - 2px);}
}
@media screen and (max-width:400px) {
    .navlist > li > a{font-size: 12px;font-size: 1.2rem;}
}

/************  sya  **************/
.sya{padding-top: 91px;padding-bottom: 130px;background: url(../images/sybg1.jpg) no-repeat center bottom;}
.syabt{text-align: center;font-size: 36px;font-size: 3.6rem;text-transform: uppercase;margin-bottom: 57px;}

.vitu3{position: relative; padding-bottom: 52.63%;height: 0;overflow: hidden;}
.vitu3 iframe {  position: absolute; top:0; left: 0; width: 100%; height: 100%; border:none;}
.vitu3 iframe body{ margin:0px !important;}

.sysp{max-width: 1045px;margin: 0 auto;margin-bottom: 51px;}
.syap1{display: block;font-size:18px;font-size:1.8rem;line-height: 28px;max-width: 1100px;margin: 0 auto;text-align: center;margin-bottom: 13px;}
.syap2{display: block;width: 222px;height: 34px;line-height: 34px;text-align: center;background: #333333;color: #fff;margin: 0 auto;text-transform: uppercase;font-size:16px;font-size:1.6rem;margin-top: 41px;}

@media screen and (max-width:999px) {
    .sya{padding-top: 50px;padding-bottom: 60px;}
    .syabt{margin-bottom: 40px;}
}
@media screen and (max-width:767px) {
    .syabt{font-size:30px;font-size:3rem;}
    .sya{padding-top: 50px;padding-bottom: 60px;}
    .syap1{font-size:16px;font-size:1.6rem;}
    .sysp{margin-bottom: 30px;}
}
@media screen and (max-width:500px) {
    .sya{padding-top: 30px;padding-bottom: 35px;}
    .syabt{font-size:24px;font-size:2.4rem;margin-bottom: 20px;}
    .syap1{font-size:14px;font-size:1.4rem;}
}

/**************  syp1  ************/
.syp{padding-top: 151px;padding-bottom: 160px;}
.syp1{background: url(../images/sybg2.jpg) no-repeat center top;}
.syp2{background: url(../images/sybg3.jpg) no-repeat center top;}
.syppart{width: 885px;}
.sypbt{font-weight: bold;font-size: 40px;font-size: 4rem;padding-left: 39px;}
.sypbt a{color: #fff;}
.sypp1{color: #fff;display: block;font-size:22px;font-size:2.2rem;max-width: 680px;line-height: 30px;margin-top: 17px;margin-bottom: 44px;padding-left: 39px;}

.syp .img-scroll{ position: relative;}

.syp .prev, .syp .next { position: absolute; display: block; width: 21px; height: 38px; top: 50%; margin-top: -19px;color: #fff; text-align: center; line-height: 100px; }

.syp .prev { left: 0; cursor: pointer; background: url("../images/ps.png") no-repeat; }

.syp .next { right: 0; cursor: pointer; background: url("../images/px.png") no-repeat; }

.syp .img-list { position: relative; width: 807px; overflow: hidden; margin: 0 auto; }

.syp .img-list ul { width: 10000%; }

.syp .img-list li { float: left; display: inline; width: 260px; margin-right: 13px; position: relative; position: relative; text-align: center; }

.syp .img-list li img { width: 100%; max-width: 356px; max-height: 370px; display: block;}

.sypbox{position: relative;overflow: hidden;}
.sypcover{position: absolute;left: 0;top: -100%;width: 100%;height: 100%;background: url(../images/sypcover.png);}
.sypcover span{display: block;text-align: center;font-size: 26px;font-size: 2.6rem;text-transform: uppercase;color: #fff;padding-top: 36%;padding-bottom: 63px;background: url(../images/syjia.png) no-repeat center bottom;}
.sypbox:hover .sypcover{top: 0;}

@media screen and (max-width:999px) {
    .syppart{width: 90%;margin: 0 auto;float: none;}
    .syp .img-list{width: 90%;}
    .syp .img-list li{width: 0.31333%;margin: 0 0.01%;}
    .syp{padding-top: 100px;padding-bottom: 100px;}
    .sypcover{position: static;}
    .sypcover span{padding-bottom: 0;background: none;padding-top: 0;margin-top: 15px;font-size:20px;font-size:2rem;}
}
@media screen and (max-width:767px) {
    .sypbt{font-size: 30px;font-size: 3rem;}
    .sypp1{font-size:18px;font-size:1.8rem;}
    .syp{padding-top: 60px;padding-bottom: 60px;}
    .sypcover span{font-size:16px;font-size:1.6rem;}
}
@media screen and (max-width:550px) {
    .syp .img-list li{width: 0.48%;}
    .syp .img-list{width: 85%;}
    .syppart{width: 100%;}
}
@media screen and (max-width:500px) {
    .sypbt{font-size:24px;font-size:2.4rem;}
    .sypp1{font-size:14px;font-size:1.4rem;margin-bottom: 25px;}
    .syp{padding-top: 40px;padding-bottom: 40px;}
}
@media screen and (max-width:350px) {
    .syp .img-list li{width: 0.98%;}
    .syp .img-list{width: 80%;}
}

/************  syly  *************/
.syly{padding-top: 91px;padding-bottom: 94px;background: #f6f6f6;}
.sybt{text-align: center;font-size: 36px;font-size: 3.6rem;font-weight: bold;}
.sypdes{display: block;font-size:20px;font-size:2rem;color: #666666;line-height: 28px;max-width: 1031px;margin: 0 auto;text-align: center;margin-top: 5px;margin-bottom: 49px;}

.syly .img-scroll{ position: relative;}

.syly .prev, .syly .next { position: absolute; display: block; width: 21px; height: 38px; top: 39%; margin-top: -19px;color: #fff; text-align: center; line-height: 100px; }

.syly .prev { left: 0; cursor: pointer; background: url("../images/ps2.png") no-repeat; }

.syly .next { right: 0; cursor: pointer; background: url("../images/px2.png") no-repeat; }

.syly .img-list { position: relative; width: 1097px; overflow: hidden; margin: 0 auto; }

.syly .img-list ul { width: 10000%; }

.syly .img-list li { float: left; display: inline; width: 356px; margin-right: 14px; position: relative; position: relative; text-align: center; }

.syly .img-list li img { width: 100%; max-width: 356px; max-height: 370px; display: block;}

.sylyp1{display: block;font-size:22px;font-size:2.2rem;color: #666666;line-height: 28px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-top: 14px;margin-bottom: 3px;}
.sylyp2{display: block;font-size:16px;font-size:1.6rem;color: #666666;line-height: 28px;height: 56px;overflow: hidden;}
.sylyp1:hover{color: #000;}
.sylyp2:hover{color: #000;}

@media screen and (max-width:1219px) {
    .syly .img-list{width: 905px;}
    .syly .img-list li{width: 292px;}
}
@media screen and (max-width:999px) {
    .syly .img-list{width: 90%;}
    .syly .img-list li{width: 0.313333%;margin: 0 0.01%;}
    .sylyp1{font-size:16px;font-size:1.6rem;}
    .sylyp2{font-size:14px;font-size:1.4rem;}

    .syly{padding-top: 60px;}
    .sybt{font-size: 30px;font-size: 3rem;}
    .sypdes{font-size:16px;font-size:1.6rem;}
}
@media screen and (max-width:767px) {
    .syly{padding-top: 40px;padding-bottom: 60px;}
}
@media screen and (max-width:600px) {
    .syly .img-list{width: 85%;}
    .syly .img-list li{width: 0.48%;}
}
@media screen and (max-width:500px) {
    .sybt{font-size:24px;font-size:2.4rem;}
    .sypdes{font-size:14px;font-size:1.4rem;margin-bottom: 20px;}
}
@media screen and (max-width:400px) {
    .syly .img-list li{width: 0.98%;}
}

/***********  syn  ***********/
.syn{padding-bottom: 79px;background: #f6f6f6;padding-top: 146px;}

.synzuo{width: 600px;}
.synyou{padding-top: 7px;}
.synyou img{width: 100%;max-width: 554px;max-height: 353px;}

.synbt{font-size: 36px;font-size: 3.6rem;text-transform: uppercase;margin-bottom: 44px;}
.synbt a{color: #666666;}
.m3_tl{ width: 148px; height: 113px; background: #fff; position: absolute;left: 0;top: 0;}

.m3_tl span{ display: block; width: 100%; text-align: center;color: #666666; }
.m3_tl .sp1{ font-size: 60px; font-size: 6rem;padding-top: 14px;line-height: 66px;}
.m3_tl .sp2{ font-size: 18px; font-size:1.8rem;}
.m3_tc{ max-width: 811px; padding-left: 171px; color: #333; }
.m3_tc a{ color: #333333; display: block;}
.m3_tc a:hover{ color: #000;}
.main3 .title:hover{ color: #000;}

.m3_tc .tit{display: block;padding-bottom: 8px; font-size: 18px; font-size: 1.8rem; line-height: 30px;color: #333;text-transform: uppercase; overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.m3_tc .p1{ font-size: 14px; font-size: 1.4rem; line-height: 26px; color: #666666;height: 78px;overflow: hidden;}

.m3_tc2{ position: relative; top:-5px; }

.main3_top{padding-bottom: 5px;margin-bottom: 39px;position: relative;}

@media screen and (min-width:1000px) and (max-width:1219px) {
    .synyou{width: 450px;padding-top: 72px;}
	.synzuo{width: 495px;}
	.m3_tl{width: 115px;}
	.m3_tc{padding-left: 130px;}
    .syn{padding-top: 70px;}
}
@media screen and (max-width:999px) {
    .syn{padding-top: 60px;}
	.synzuo{margin-left: 0;width: 96%;margin: 0 2%;float: none;}
	.synyou{width: 100%;text-align: center;}
}
@media screen and (max-width:767px) {
    .synbt{font-size: 30px;font-size: 3rem;}
    .syn{padding-top: 40px;padding-bottom: 50px;}
}
@media screen and (max-width:500px) {
	.m3_tl{width: 100px;}
	.m3_tl .sp1{font-size: 50px;font-size: 5rem;}
	.m3_tl .sp2{font-size:16px;font-size:1.6rem;}
	.m3_tc{padding-left: 115px;}
	.syrep1{font-size:18px;font-size:1.8rem;}
	.m3_tc .p1{font-size: 12px;font-size: 1.2rem;}
    .m3_tc .tit{font-size:14px;font-size:1.4rem;}
}
@media screen and (max-width:400px) {
	.synbt{margin-bottom: 20px;}
	.m3_tc{padding-left: 0;padding-top: 95px;}
	.m3_tl .sp1{line-height: 50px;font-size: 40px;font-size: 4rem;}
	.m3_tl{height: 85px;}
}


/*************  bottom  ************/
.bottom{background: #e0e0e0;padding-top: 60px;}
.bottom p,.bottom li,.bottom a,.bottom span{color: #333;}
.bottom a:hover{color: #000;}
.div>a,.div>span{display: block;font-size:22px;font-size:2.2rem;color: #333333;margin-bottom: 31px;text-transform: uppercase;}

.div1{margin-right: 258px;}
.div1 li{line-height: 30px;}
.div2{width: 335px;}
.div2 li{line-height: 36px;padding-left: 27px;}
.bli1{background: url(../images/bi1.png) no-repeat left 9px;}
.bli2{background: url(../images/bi2.png) no-repeat left 9px;}
.bli3{background: url(../images/bi3.png) no-repeat left 9px;}
.bli4{background: url(../images/bi4.png) no-repeat left 9px;}

.div3{width: 319px;}
.bsjj a{margin-right: 27px;position: relative;transition: 0.5s;top: 0;}
.bsjj{padding-top: 15px;}
.bsjj a:hover{top: -10px;}
.copyright,.support{line-height: 26px;}
.cnzz{padding-left: 10px;}

.link{padding-top: 17px;padding-bottom: 17px;line-height: 30px;min-height: 30px;margin-top: 15px;text-transform: uppercase;}
.link a{padding: 0 20px;}
.link a,.link span{display: inline-block;}
.link a:hover{color: #000;}

@media screen and (min-width:1000px) and (max-width:1219px) {
    .div1{margin-right: 170px;}
}
@media screen and (max-width:999px) {
    .div1{width: 22%;margin-right: 2%;}
    .div2{width: 32%;}
    .div3{width: 42%;}
    .div > a, .div > span{font-size:20px;font-size:2rem;}
}
@media screen and (max-width:767px) {
    .div1,.div2{display: none;}
    .div3{width: 100%;margin-top: 0;}
    .div3>span{display: none;}
    .bottom{padding-top: 20px;padding-bottom: 20px;}
    .link a,.link span{display: none;}
    .link{margin-top: 0;}
}

/************  bread  *************/
.mianbao{ font-size:16px; font-size:1.6rem; line-height:24px;padding-top:15px;border-bottom: 1px solid #a2a2a2;padding-bottom: 13px;}
.mianbao a:hover{ text-decoration: underline;}
.co00{ text-decoration: underline;}

.fymain{padding-top: 26px;padding-bottom: 129px;}

/**********   product1   **********/
.pfl{margin-bottom: 27px;}
.pfl li{width: 380px;height: 120px;text-align: center;font-size:22px;font-size:2.2rem;float: left;margin-left: 30px;line-height: 120px;}
.pfl li a{display: block;color: #fff;}
.pfl1{background: url(../images/fl1.jpg) no-repeat center center;}
.pfl2{background: url(../images/fl2.jpg) no-repeat center center;}
.pfl3{background: url(../images/fl3.jpg) no-repeat center center;}

.pbox{width: 282px;float: left;margin-left: 24px;text-align: center;margin-bottom: 50px;}
.ptu{display: block;border: 1px solid #eaeaea;}
.ptu img{display: block;width: 100%;max-width: 356px;max-height: 370px;}
.pp1{display: block;font-size:16px;font-size:1.6rem;line-height: 30px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-top: 14px;}
.pp1 input{cursor: pointer;margin-right: 12px;}
.pp1 a{font-size:22px;font-size:2.2rem;color: #666666;}
.pp2{display: block;color: #666666;line-height: 28px;height: 56px;overflow: hidden;margin-top: 6px;margin-bottom: 23px;font-size:16px;font-size:1.6rem;}
.pp3{display: block;width: 228px;height: 38px;line-height: 38px;border: 1px solid #666;text-align: center;margin: 0 auto;color: #666666;text-transform: uppercase;}
.pp3:hover{background: #666666;border: 1px solid #666666;color: #fff;}

.pp1 a:hover{color: #000;}
.pp2:hover{color: #000;}

.ms_ye{text-align: center;margin-top: 109px;}
.ms_ye a{display: inline-block;width: 24px;line-height: 24px;text-align: center;margin-left:3px;margin-right:4px;font-size: 12px;font-size: 1.2rem;background: #fff;border-radius: 50%;color: #0a0a0a;border: 1px solid #0a0a0a;}
.ms_sx{width: auto!important;background: none!important;;margin:0 10px!important;color: #333!important;border: none!important;}
.ms_sx:hover{color: #1a4e7d!important;}
.ms_ye .ms_current_ye{background: #0a0a0a; color: #fff;}
.ms_ye a:hover{background: #0a0a0a;color: #fff;}

.xiangshang{position: absolute;right: 0;bottom: 0;padding-left: 30px;background: url(../images/xs.jpg) no-repeat left 6px;}
.xiangshang input{width: 170px;height: 32px;line-height: 32px;text-align: center;color: #fff;border:1px solid #333333;cursor: pointer;background: #333333;}
.xiangshang input:hover{background: #fff;color: #333;}
.xiangshang span{font-size:16px;font-size:1.6rem;color: #4b4b4b;padding-right: 15px;}

.product1 .ms_ye{text-align: left;position: relative;max-width: 844px;margin: 0 auto;margin-top: 110px;}

@media screen and (max-width:1219px) {
    .pfl li{width: 306px;}
    .pbox{width: 230px;margin-left: 20px;}
    .pp1 a{font-size:18px;font-size:1.8rem;}
    .pp3{width: 200px;}
    .product1 .ms_ye{margin-top: 40px;}
}
@media screen and (max-width:999px) {
    .pbox{width: 31.333%;margin: 0 1%;margin-bottom: 30px;}
    .pp1{font-size:14px;font-size:1.4rem;}
    .ms_ye{text-align: center;}
    .ms_ye a{margin-bottom: 10px;}
    .xiangshang{position: static;display: inline-block;}
    .product1 .ms_ye{text-align: center;}
    .fymain{padding-bottom: 40px;}
    .pfl li{width: 29.3333%;margin: 0 2%;}
}
@media screen and (max-width:767px) {
    .pfl li{width: 31.333%;margin: 0 1%;height: 80px;line-height: 80px;}
    .pp1 a{font-size:16px;font-size:1.6rem;}
    .pp2{font-size:14px;font-size:1.4rem;}
    .pp3{width: 160px;}
}
@media screen and (max-width:600px) {
    .pbox{width: 48%;}
    .pfl li{font-size:18px;font-size:1.8rem;height: 54px;line-height: 54px;}
}
@media screen and (max-width:400px) {
    .pp3{width: 130px;height: 32px;line-height: 32px;}
    .pfl li{font-size:16px;font-size:1.6rem;}
}

/************  product2  *************/
.product2 .pfl{margin-bottom: 74px;}

#showArea{margin-top: 13px;margin-bottom:13px; }
#showArea img{cursor:pointer;width:125px;height:130px;border: 1px solid #eaeaea;margin: 0 auto;}
#showArea a{ display:inline-block;text-align: left;margin-bottom: 16px;}
#showArea a:hover img{border: 1px solid #000;}
#showArea a.selected img{border: 1px solid #000;}

#main_img{cursor:pointer;display:block;}
#gotop{cursor:pointer;display:block;  width: 28px;height: 14px;background: url(../images/top.png) no-repeat right center;margin: 0 auto;}
#gobottom{cursor:pointer;display:block; width: 28px;height: 14px;background: url(../images/bottom.png) no-repeat right center;margin: 0 auto;}

#showArea{height:434px; overflow:hidden;}
.MagicZoomBigImageCont {background:#FFF;}
.MagicZoomBigImageCont img{width: 700px!important;display: block;}
.MagicZoomPup {border:0px solid #aaa;background:#ffffff;}

.left-pro{width:647px;text-align:left;margin-bottom:27px;}
.left-pro .t1{width:127px;float:left;text-align: right;}
.left-pro .t2{width:476px;text-indent:0;float:right; border: 1px solid #eaeaea;}
.left-pro .t2 a{ display:block;margin-bottom: 0px;}
.left-pro .t2 img{text-indent:0;display: block;}

.titleh1{margin-bottom: 40px;}
.titleh1 h1{font-size: 24px;font-size: 2.4rem;line-height: 28px;color: #333333;}
.content{width: 483px;}

.cpxx{line-height: 30px;color: #333333;font-size:16px;font-size:1.6rem;}
.cpxx p,.cpxx li,.cpxx span,.cpxx b{color: #333;}
.cpxx a{color: #000;}
.cpxx img,.cpxx iframe{max-width: 100%;}

.btn{padding-top: 48px;}
.btn a{display: inline-block;width: 164px;height: 46px;line-height: 46px;text-align: center;color: #333333;transition: 0.5s;border: 1px solid #000003;}
.btn a:hover{background: #333333;color: #fff;}
.btn1{margin-right: 47px;}
.share{padding-top: 75px;}
.share1{display: inline-block;position: relative;top: 3px;padding-right: 10px;font-size:16px;font-size:1.6rem;color: #666666;}
.share2{display: inline-block;}

.littlebt{font-size:24px;font-size:2.4rem;margin-bottom: 15px;}

.chanpinx{line-height: 24px;padding-top: 3px;}
.chanpinx a{color: #000;}
.chanpinx img{max-width: 100%;}
.chanpinx iframe{max-width: 100%;}

.chanpinx table,.nex table,.cpxx table{ width:97%; border-collapse:collapse; line-height:24px;}
.chanpinx tr td,.nex tr td,.cpxx tr td{ line-height:41px; padding:2px;}

.table table{ width:100%; border-collapse:collapse; line-height:41px; border:1px solid #fff;}
.table table tr td{ padding: 0 9px; border:1px solid #fff;}
.table table tr:nth-child(2n) td{background: #f1f1f1;}
@media screen and (max-width:1000px){
.table{ width:100%;  overflow:scroll;}
.table table{ width:885px;}	
}


.des{font-size: 24px;font-size: 2.4rem;margin-top: 71px;margin-bottom: 23px;padding-bottom: 14px;background: url(../images/desbg.png) no-repeat left bottom;}
.des2{margin-bottom: 37px;}

.img980 {position: relative;margin: 0 auto;width: 100%;}
.img980 .prev, .img980 .next {position: absolute;display: block;width: 16px;height: 25px;top:40%;color: #fff;text-align: center;line-height: 100px}
.img980 .prev {left: 0;cursor: pointer;background: url("../images/sbs.png") no-repeat;}
.img980 .next {right: 0;cursor: pointer;background: url("../images/sbx.png") no-repeat;}
.img980 .img-list {position:relative;width:90%;overflow: hidden;margin: 0 auto;}
.img980 .img-list ul {width: 10000%;}
.img980 .img-list li {float: left;display: inline;width: 0.31%;margin: 0 0.01%;position: relative;position: relative;text-align: center;}
.img980 .img-list li span,.img980 .img-list li a{color: #fff;display: block;border: 1px solid #dadada;}
.img980 .img-list li img{width: 100%;max-width: 390px;max-height: 390px;}

.img980{display: none;}

.product2 .pbox{margin-bottom: 0;}


@media screen and (max-width:1219px) {
    .MagicZoomPup{background: rgba(0,0,0,0)!important;}
    .MagicZoomBigImageCont{display: none!important;}
    .left-pro{width: 620px;}
    .content{width: 340px;}
    .btn1{margin-right: 10px;}
    .btn a{width: 144px;}
    .share{padding-top: 40px;}
}
@media screen and (max-width:999px) {
    .img980{display: block;}
    .left-pro{display: none;}
    .content{width: 100%;margin-top: 30px;}
    .product2 .pfl{margin-bottom: 35px;}
    .des{margin-top: 45px;}
}
@media screen and (max-width:500px) {
    .img980 .img-list li{width: 0.48%;}
    .btn a{margin-bottom: 10px;}
    .titleh1{margin-bottom: 25px;}
    .littlebt{font-size:20px;font-size:2rem;}
    .titleh1 h1{font-size:20px;font-size:2rem;}
}


/************  about1  ***********/
.afl{text-align: center;background: url(../images/axu.jpg) repeat-x center 66px;margin-bottom: 80px;padding-top: 12px;}
.afl li{display: inline-block;}
.afl li a{display: block;font-size:16px;font-size:1.6rem;line-height: 24px;padding-top: 130px;background: url(../images/abiao.jpg) no-repeat center top;}

.ayou img{width: 100%;max-width: 517px;}
.ayou{margin-left: 40px;}
.azuo{padding-top: 6px;}
.ap1{font-size:20px;font-size:2rem;text-transform: uppercase;padding-bottom: 10px;background: url(../images/abtxian.jpg) no-repeat left bottom;margin-bottom: 50px;}
.ap2{font-size:16px;font-size:1.6rem;color: #666666;line-height: 28px;margin-bottom: 28px;}
.ap3{margin-top: 65px;}

.asp{margin-top: 67px;position: relative;}
.avm{position: absolute;width: 142px;height: 35px;line-height: 35px;text-align: center;background: #333333;border-radius: 5px;top: 10px;right: 0;color: #fff;font-size:16px;font-size:1.6rem;text-transform: uppercase;}
.asp li{width: 387px;float: left;margin-left: 19px;cursor: pointer;}
.asp li>p{font-size:20px;font-size:2rem;line-height: 30px;margin-top: 13px;text-align: center;}
.spbox{position: relative;overflow: hidden;}
.sptu img{display: block;width: 100%;max-width: 387px;max-height: 287px;}
.spcover1{position: absolute;left: 0;top: -100%;width: 100%;height: 100%;z-index: 99;background: url(../images/spcover.png);transition: 0.5s;}
.spcover2{position: absolute;left: 0;top: 0;width: 100%;height: 100%;z-index: 999;}
.spcover2 img{width: 70px;height: 70px;position: absolute;left: 50%;margin-left: -35px;top: 50%;margin-top: -35px;}
.spbox:hover .spcover1{top: 0;}

.atu{position: relative;margin-top: 72px;}

.atu li{width: 276px;float: left;margin-left: 32px;text-align: center;}
.zsbox{position: relative;overflow: hidden;}
.zstu img{width: 100%;max-width: 276px;max-height: 218px;display: block;}
.zscover{position: absolute;left: 0;top: -100%;width: 100%;height: 100%;background: url(../images/zscover.png);}
.zscover img{position: absolute;width: 37px;height: 37px;left: 50%;margin-left: -19px;top: 50%;margin-top: -19px;}
.atu li>p{font-size:20px;font-size:2rem;line-height: 28px;margin-top: 14px;}
.zsbox:hover .zscover{top: 0;}


/***********  sycover  ***********/
.sycover { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: url(../images/sypcover.png);z-index: 9999; display: none;}

.vitu2 { position: relative; padding-bottom: 74.16%; height: 0; overflow: hidden; }

.vitu2 iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none; }

.vitu2 iframe body { margin: 0px !important; }

.fdsp { max-width: 387px; margin: 0 auto; margin-top: 20%; position: relative; }

.fdsp > img { cursor: pointer; position: absolute; right: 0; top: -24px; }


/*********************fangdaxiaoguo**********************/
#baguetteBox-overlay { display: none; opacity: 0; position: fixed; overflow: hidden; top: 0; left: 0; width: 100%; height: 100%; background-color: #222; background-color: rgba(0, 0, 0, 0.8); -webkit-transition: opacity .5s ease; -o-transition: opacity .5s ease; -moz-transition: opacity .5s ease; transition: opacity .5s ease; z-index: 99999999999999999999999999999999999999; }

#baguetteBox-overlay.visible { opacity: 1; }

#baguetteBox-overlay .full-image { display: inline-block; position: relative; width: 100%; height: 100%; text-align: center; }

#baguetteBox-overlay .full-image figure { display: inline; margin: 0; height: 100%; }

#baguetteBox-overlay .full-image img { display: inline-block; width: auto; height: auto; max-height: 100%; max-width: 100%; vertical-align: middle; -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); }

#baguetteBox-overlay .full-image figcaption { display: block; position: absolute; bottom: 0; width: 100%; text-align: center; line-height: 1.8; color: #ccc; background-color: #000; background-color: rgba(0, 0, 0, 0.6); font-family: Verdana, Geneva, sans-serif; }

#baguetteBox-overlay .full-image:before { content: ""; display: inline-block; height: 50%; width: 1px; margin-right: -1px; }

#baguetteBox-slider { position: absolute; left: 0; top: 0; height: 100%; width: 100%; white-space: nowrap; -webkit-transition: left .4s ease,-webkit-transform .4s ease; transition: left .4s ease,-moz-transform .4s ease; transition: left .4s ease,-webkit-transform .4s ease; -o-transition: left .4s ease,-o-transform .4s ease; -moz-transition: left .4s ease,transform .4s ease,-moz-transform .4s ease; transition: left .4s ease,transform .4s ease; transition: left .4s ease,transform .4s ease,-webkit-transform .4s ease,-moz-transform .4s ease,-o-transform .4s ease; }

#baguetteBox-slider.bounce-from-right { -webkit-animation: bounceFromRight .4s ease-out; -moz-animation: bounceFromRight .4s ease-out; animation: bounceFromRight .4s ease-out; }

#baguetteBox-slider.bounce-from-left { -webkit-animation: bounceFromLeft .4s ease-out; -moz-animation: bounceFromLeft .4s ease-out; animation: bounceFromLeft .4s ease-out; }

.baguetteBox-button#next-button, .baguetteBox-button#previous-button { top: 50%; top: -webkit-calc(50% - 30px); top: -moz-calc(50% - 30px); top: calc(50% - 30px); width: 44px; height: 60px; }

.baguetteBox-button { position: absolute; cursor: pointer; outline: 0; padding: 0; margin: 0; border: 0; -moz-border-radius: 15%; border-radius: 15%; background-color: #323232; background-color: rgba(50, 50, 50, 0.5); color: #ddd; font: 1.6em sans-serif; -webkit-transition: background-color .4s ease; -o-transition: background-color .4s ease; -moz-transition: background-color .4s ease; transition: background-color .4s ease; }

.baguetteBox-button:hover { background-color: rgba(50, 50, 50, 0.9); }

.baguetteBox-button#next-button { right: 2%; }

.baguetteBox-button#previous-button { left: 2%; }

.baguetteBox-button#close-button { top: 20px; right: 2%; right: -webkit-calc(2% + 6px); right: -moz-calc(2% + 6px); right: calc(2% + 6px); width: 30px; height: 30px; }

.baguetteBox-button svg { position: absolute; left: 0; top: 0; }

.spinner { width: 40px; height: 40px; display: inline-block; position: absolute; top: 50%; left: 50%; margin-top: -20px; margin-left: -20px; }

.double-bounce1, .double-bounce2 { width: 100%; height: 100%; -moz-border-radius: 50%; border-radius: 50%; background-color: #fff; opacity: .6; position: absolute; top: 0; left: 0; -webkit-animation: bounce 2s infinite ease-in-out; -moz-animation: bounce 2s infinite ease-in-out; animation: bounce 2s infinite ease-in-out; }

.double-bounce2 { -webkit-animation-delay: -1s; -moz-animation-delay: -1s; animation-delay: -1s; }

@-webkit-keyframes bounceFromRight { 0% { margin-left: 0; }
  50% { margin-left: -30px; }
  100% { margin-left: 0; } }

@-moz-keyframes bounceFromRight { 0% { margin-left: 0; }
  50% { margin-left: -30px; }
  100% { margin-left: 0; } }

@keyframes bounceFromRight { 0% { margin-left: 0; }
  50% { margin-left: -30px; }
  100% { margin-left: 0; } }

@-webkit-keyframes bounceFromLeft { 0% { margin-left: 0; }
  50% { margin-left: 30px; }
  100% { margin-left: 0; } }

@-moz-keyframes bounceFromLeft { 0% { margin-left: 0; }
  50% { margin-left: 30px; }
  100% { margin-left: 0; } }

@keyframes bounceFromLeft { 0% { margin-left: 0; }
  50% { margin-left: 30px; }
  100% { margin-left: 0; } }

@-webkit-keyframes bounce { 0%, 100% { -webkit-transform: scale(0);
    transform: scale(0); }
  50% { -webkit-transform: scale(1);
    transform: scale(1); } }

@-moz-keyframes bounce { 0%, 100% { -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0); }
  50% { -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1); } }

@keyframes bounce { 0%, 100% { -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); }
  50% { -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }


@media screen and (max-width:1219px) {
.ap3{margin-top: 30px;}
.asp li{width: 314px;}
.atu li{margin-left: 20px;width: 230px;}
}
@media screen and (max-width:999px) {
    .ayou{width: 400px;}
    .afl{margin-bottom: 60px;}
    .asp li{width: 31.333%;margin: 0 1%;}
    .asp li > p{font-size:16px;font-size:1.6rem;}
    .atu li{width: 23%;margin: 0 1%;}
    .atu li > p{font-size:16px;font-size:1.6rem;}
    .spcover1{display: none;}
    .zscover{display: none;}
}
@media screen and (max-width:767px) {
    .afl{margin-bottom: 35px;}
    .ayou{float: none;margin: 0;text-align: center;width: auto;}
    .ap1{margin-bottom: 25px;}
    .asp{margin-top: 50px;}
    .atu{margin-top: 50px;}
    .spcover2 img{width: 40px;height: 40px;margin-left: -20px;margin-top: -20px;}
    .avm{font-size:14px;font-size:1.4rem;width: 120px;top: 0;}
}
@media screen and (max-width:550px) {
    .ap1{font-size:18px;font-size:1.8rem;}
    .atu li{width: 48%;margin-bottom: 20px;}
    .asp li > p,.atu li > p{font-size:14px;font-size:1.4rem;}
}
@media screen and (max-width:400px) {
    .asp li{width: 98%;margin-bottom: 20px;}
    .atu{margin-top: 25px;}
    .avm{font-size: 12px;font-size: 1.2rem;width: 100px;}
}

/**********  about2  **********/
.about2 .ap2{color: #333;}
.lunboa {padding-bottom: 22px;}
.lunboa .ban .ban2 ul li{position: relative;}
.lunboa .ban .ban2 ul li .mzz{ position: absolute;bottom: 0;left: 0;width: 100%;display: block;text-align: center;height: 32px; color: #fff;line-height: 32px;background:rgba(0,0,0,0.7);font-family: Verdana, Geneva, Tahoma, sans-serif}
.lunboa .ban .ban2 ul li .mzz a{width: 100%; }

.lunboa{position: relative;}
img{ border:0;}
/*1*/
.ban{ width:1144px; height:1168px; position:relative; 
/*overflow:hidden;*/
margin:0 auto 0 auto;}
.ban2{ width:1144px; height:904px; position:relative; overflow:hidden;margin: 0 auto;right: 0px;}
.ban2 ul{ position:absolute; left:0; top:0;}
.ban2 ul li{ width:1144px; height:904px;background: #fff;}
.prev{ float:left; cursor:pointer;}

/*2*/
.num{ height:234px;overflow:hidden; width:942px; position:relative;margin: 0 auto;}
.min_pic{ padding-top:29px;}
.num ul{ position:absolute; left:0; top:0;}
.num ul li{ width:296px; height:234px; margin-right:27px; padding:0px;}
.num ul li a{display: block;background: transparent;}
.num ul li a img{width: 296px;height: 234px;}
.num ul li.on a{background: #e3382e;}
.num ul li.on{background: url(../images/sjt.png) no-repeat center top;}
.prev_btn1{ width:16px; text-align:center; height:21px; margin-top:47px; margin-right:30px; cursor:pointer; float:left;}
.next_btn1{  width:16px; text-align:center; height:21px; margin-top:47px;cursor:pointer;float:right;}
.prev1{ position:absolute; bottom:107px; left:47px; width:22px; height:55px;z-index:9;cursor:pointer;background: url("../images/lbs.png") no-repeat; }
.next1{ position:absolute; bottom:107px; right:47px; width:22px; height:55px;z-index:9;cursor:pointer;background: url("../images/lbx.png") no-repeat;}
.mhc{
        z-index: 99999;
        background:#000; width:100%;opacity:0.5;-moz-opacity:0.5;filter:alpha(Opacity=50); position:fixed; left:0; top:0; display:none;}

/*3*/
.pop_up{ width:845px; height:546px; padding:10px; background:#fff; position:fixed; -position:absolute; left:50%; top:50%; margin-left:-498px; margin-top:-273px; display:none; z-index:9999999;}
.pop_up_xx{ width:40px; height:40px; position:absolute; top:-40px; right:0; cursor:pointer;}
.pop_up2{ width:500px; height:500px; position:relative; overflow:hidden;}
.pop_up2{ width:845px; height:546px; position:relative; overflow:hidden; float:left;}
.pop_up2 ul{ position:absolute; left:0; top:0;}
.pop_up2 ul li{ width:845px; height:546px; float:left;}

.tukuang{position: relative;display: block;}
.lunboa {padding-bottom: 0px;padding-top: 36px;}
.lunboa .ban .ban2 ul li{position: relative;}
.lunboa .ban .ban2 ul li .mzz{ position: absolute;bottom: 0;left: 0;width: 100%;display: block;text-align: center;height: 39px; color: #fff;line-height: 39px;background:rgba(0,0,0,0.5);font-size: 16px;font-size: 1.6rem;}

.lunboa .ban .ban2 ul li .mzz a{width: 100%; }


.xiaoping{display: none;}
.xiaoping li{width: 48%;margin: 0 1%;margin-bottom: 20px;float: left;}
.xiaoping li img{width: 100%;max-width: 845px;max-height: 546px;}
.xiaoping li p{text-align: center;margin-top: 10px;}

@media screen and (max-width:1219px) {
.lunboa{display: none;}
.xiaoping{display: block;padding-top: 20px;}
}


/************  about3  **********/
.vbox{background: #f4f4f4;margin-bottom: 20px;}
.vitu{position: relative; padding-bottom: 74.24%;height: 0;overflow: hidden;}
.vitu iframe {  position: absolute; top:0; left: 0; width: 100%; height: 100%; border:none;}
.vitu iframe body{ margin:0px !important;}
.vbox1 .vzuo{width: 431px;margin-right: 48px;}
.vbox2 .vzuo{width: 431px;margin-left: 48px;}
.vbox1 .vyou{padding-top: 80px;padding-right: 48px;}
.vbox2 .vyou{padding-top: 80px;padding-left: 48px;}
.vp1{font-size:20px;font-size:2rem;font-weight: bold;color: #333333;line-height: 32px;}
.vp2{color: #4d4d4d;line-height: 24px;margin-top: 22px;}

@media screen and (max-width:999px) {
    .vbox .vzuo{width: 350px;}
    .vbox .vyou{padding-top: 40px;}
    .ms_ye{margin-top: 60px;}
}
@media screen and (max-width:900px) {
    .vp1{font-size:16px;font-size:1.6rem;}
    .vp2{margin-top: 10px;}
    .vbox .vyou{padding-top: 20px;}
    .vbox1 .vzuo{margin-right: 20px;}
    .vbox2 .vzuo{margin-left: 20px;}
    .vbox1 .vyou{padding-right: 20px;}
    .vbox2 .vyou{padding-left: 20px;}
}
@media screen and (max-width:750px) {
    .vbox .vzuo{float: none;width: auto;max-width: 431px;margin: 0 auto;}
    .vbox .vyou{padding: 10px;text-align: center;}
}

/**********  news1  **********/
.news1{padding-top: 50px;}
.nzuo{width: 755px;}
.nyou{width: 366px;}

.nbox{position: relative;margin-bottom: 41px;}
.riqi{position: absolute;left: 0;top: -11px;width: 84px;text-align: center;}
.riqi p{font-size: 60px;font-size: 6rem;color: #666666;border-bottom: 2px solid #666666;line-height: 64px;margin-bottom: 14px;}
.riqi b{font-weight: normal;font-size:18px;font-size:1.8rem;color: #666666;display: block;}
.fynnr{margin-left: 98px;}
.fynnr a{display: block;}
.np1 img{width: 100%;max-width: 657px;max-height: 419px;}
.np2{font-size:22px;font-size:2.2rem;color: #666666;line-height: 26px;margin-top: 12px;margin-bottom: 14px;}
.np3{font-size:16px;font-size:1.6rem;color: #666666;line-height: 28px;}

.nyshang>p{font-size:20px;font-size:2rem;color: #666666;text-transform: uppercase;line-height: 26px;margin-bottom: 18px;}
.nyshang li{border-bottom: 1px solid #a9a9a9;line-height: 30px;padding-top: 20px;padding-bottom: 20px;}
.nyshang li a{display: block;font-size:18px;font-size:1.8rem;color: #666666;}

.nyxia{margin-top: 101px;}
.nyxia>p{font-size:20px;font-size:2rem;text-transform: uppercase;line-height: 26px;margin-bottom: 23px;}
.nyxia>p a{color: #666666;}
.nyxia li{width: 170px;margin-bottom: 15px;}
.nyxia li>a{display: block;border: 1px solid #e6e6e6;}
.nyxia li>a img{display: block;width: 100%;max-width: 476px;max-height: 495px;}
.nyxia li p{font-size:16px;font-size:1.6rem;line-height: 26px;text-align: center;margin-top: 13px;}
.nyxia li p a{color: #666666;display: block;}

@media screen and (max-width:1219px) {
    .nzuo{width: 580px;}
}
@media screen and (max-width:999px) {
    .nzuo{width: 100%;}
    .nyou{width: 100%;margin-top: 30px;}
    .nyxia li{width: 23%;margin: 0 1%;float: left;}
    .nyxia{margin-top: 55px;}
}
@media screen and (max-width:767px) {
    .np2{font-size:18px;font-size:1.8rem;}
    .np3{font-size:14px;font-size:1.4rem;}
    .nyshang li{padding-top: 12px;padding-bottom: 12px;}
    .news1{padding-top: 30px;}
    .nyxia li p{font-size:14px;font-size:1.4rem;}
}
@media screen and (max-width:600px) {
    .nyxia li{width: 48%;margin-bottom: 20px;}
}
@media screen and (max-width:500px) {
    .riqi p{font-size: 40px;font-size: 4rem;line-height: 50px;}
    .riqi b{font-size:14px;font-size:1.4rem;}
    .riqi{width: 70px;}
    .fynnr{margin-left: 78px;}
    .nyshang li a{font-size:16px;font-size:1.6rem;}
    .np2{font-size:16px;font-size:1.6rem;}
    .nbox{margin-bottom: 25px;}
    .nyou{margin-top: 15px;}
}

/**********  news2  **********/
.xwxbt{text-align: center;font-size: 28px;font-size: 2.8rem;color: #666666;line-height: 30px;}
.xwsjsjj{text-align: center;line-height: 26px;padding-top: 12px;}
.xwsjsjj span{display: inline-block;color: #666666;padding-right: 35px;}
.nshare{display: inline-block;}
.nshare1{color: #666666;display: inline-block;position: relative;padding-right: 5px;font-size:16px;font-size:1.6rem;}
.nshare2{display: inline-block;vertical-align: middle;}
.nshare2 img{vertical-align: middle;}

.nex{font-size:14px;font-size:1.4rem;color: #333;line-height: 30px;padding-top: 26px;padding-bottom: 112px;}
.nex p,.nex li,.nex div,.nex span,.nex b{color: #333;}
.nex a{color:#000;}

.xwsx{line-height: 32px;color: #666666;}
.xwsx span{color: #666;padding-left: 24px;display: inline-block;}
.xws span{background: url(../images/xws.jpg) no-repeat left center;}
.xwx span{background: url(../images/xwx.jpg) no-repeat left center;}
.xws{background: url(../images/xwxxu.jpg) repeat-x center bottom;}
.xwsx a{color: #666666;}
.xwsx a:hover{color: #333;}

@media screen and (max-width:767px) {
    .xwxbt{font-size:20px;font-size:2rem;}
    .nex{padding-bottom: 50px;}
}

/******  contact  *******/
.contact{padding-bottom: 0;}
.cp1{text-align: center;font-size: 20px;font-size: 2rem;text-transform: uppercase;padding-top: 18px;line-height: 28px;}
.cp2{text-align: center;color: #8a8a8a;line-height: 25px;}

.cuxin{ margin-top:18px;}
.xinl{ width:462px; float:left;}
.xinr{ width:662px; float:right;}
.cuxin .int1{ padding-left:18px; margin-top:19px;}
.cuxin .xing{ background:url(../images/xing.jpg) no-repeat left 22px;}
.cuxin .int1 input{ width:439px; height:48px; border:1px solid #5c5c5c; padding-left:40px;line-height:48px; }
.cuxin .curen{ background:#fff url(../images/coren.jpg) no-repeat left center;}
.cuxin .cuxinfeng2{ background:#fff url(../images/coxinfeng.jpg) no-repeat left center;}
.cuxin .cudianhua2{ background:#fff url(../images/codianhua.jpg) no-repeat left center;}
.cuxin .cufangzi2{ background:#fff url(../images/cofang.jpg) no-repeat left center;}
.cuxin .int2{padding-left:18px; margin-top:19px;}
.cuxin .int3{padding-left:18px; margin-top:18px;}
.cuxin textarea{ width:600px; height:186px; border:1px solid #5c5c5c; padding-left:40px;line-height:48px;background:#fff url(../images/cobiji.jpg) no-repeat left top;}
.cuxin .int4{width:229px; height:48px; border:1px solid #5c5c5c; padding-left:11px;line-height:48px; float:left;}
.cuxin .cuyanzheng{ width:161px; height:50px; float:left; margin-left:8px; cursor:pointer;}
.cuxin .int5{ width:200px; height:50px; border:none; background:#333333; float:right; cursor:pointer;color:#fff;padding-left: 0;}
.ditu img{width: 100%;display: block;max-width: 1920px;max-height: 470px; }
.cuxin .int5:hover{ background:#000;}

.lxlist{background: #e0e0e0;margin-top: 112px;border-bottom: 1px solid #fff;}
.lxlist li{float: left;text-align: center;min-width: 152px;padding-left: 13px;padding-right:35px;padding-top: 47px;padding-bottom: 47px;background: url(../images/xie.png) no-repeat right center;}
.lxlist li.nobg{background: none;}
.lxlist li span{display: block;padding-top: 75px;font-size:16px;font-size:1.6rem;text-transform: uppercase;}
.lxlist li p{line-height: 20px;margin-top: 13px;}

.cli1 span{background: url(../images/ci1.png) no-repeat center top;}
.cli2 span{background: url(../images/ci2.png) no-repeat center top;}
.cli3 span{background: url(../images/ci3.png) no-repeat center top;}
.cli4 span{background: url(../images/ci4.png) no-repeat center top;}
.cli5 span{background: url(../images/ci5.png) no-repeat center top;}
.cli6 span{background: url(../images/ci6.png) no-repeat center top;}

@media screen and (min-width:1000px) and (max-width:1219px){
.xinl{ width:400px;}
.xinr{ width:564px;}
.cuxin .int1 input{ width:337px;}
.cuxin textarea{ width:503px;}
.cuxin .int4{width:179px;}
.cuxin .int5{ width:150px;}

.lxlist li{padding-left: 5px;padding-right: 27px;min-width: 130px;}
.lxlist{margin-top: 70px;}
}
@media screen and (max-width:999px){
.xinl{ width:100%;}
.xinr{ width:100%;}
.cuxin .int1 input{ width:calc(100% - 40px);}
.cuxin textarea{ width:calc(100% - 40px);}
.cuxin .int4{width:30%;}
.cuxin .int5{ width:30%; float:left; margin-left:10px;}
.ditu{ margin-left:auto;width:100%; height:auto; max-width:1181px; max-height:512px;}

.lxlist{margin-top: 55px;padding-top: 20px;padding-bottom: 20px;}
.lxlist li{padding: 0;background: none;width: 33.333%;margin-bottom: 15px;}
}
@media screen and (max-width:639px){
.cuxin .int4{width:30%;}
.cuxin .cuyanzheng{ float:left; margin-left:10px;}
.cuxin .int5{ width:100%; margin-top:20px; margin-left:auto;}
.ditu{ margin-left:auto;}
}
@media screen and (max-width:500px){
    .lxlist li{width: 50%;}
    .lxlist{margin-top: 30px;}
}

/*
font-family: 'OPENSANSBOLD_0';
*/
@media screen and (max-width:1219px) {
    
}
@media screen and (max-width:999px) {
    
}




/***********  xiala  ***********/
.yiji{position: absolute;z-index: 9999;width: 191px;background: #fff;top: -9999px;left: 0;}
.yiji li{position: relative;}
.yiji li a{display: block;line-height: 30px;padding-top: 5px;padding-bottom: 5px;padding-left: 37px;padding-right: 10px;font-size:16px;font-size:1.6rem;}
.yiji li:hover>a{background:#333333;color: #fff;}

.erji{position: absolute;left: 100%;top: -999px;padding-left: 5px;}
.erji ul{width: 191px;background: #fff;}
.navlist>li:hover .yiji{top: 100%;}
.yiji>li:hover .erji{top: 0px;}

@media screen and (max-width:999px) {
    .yiji{display: none;}
}


.ce{position: fixed;right: 0px;top: 30%;width: 69px;z-index: 9999999999;}
.ce li{height: 69px;margin-bottom: 2px;transition: 0.5s;border-radius: 5px;}
.ce li a{display: block;height: 53px;}

.ce1{background: #666666 url(../images/cei1.png) no-repeat center center;}
.ce2{background: #666666 url(../images/cei2.png) no-repeat center center;}
.ce3{background: #666666 url(../images/cei3.png) no-repeat center center;}


@media screen and (max-width:767px) {
    .ce{bottom: 0;top: auto;width: 100%;}
    .ce li{width: 33.333%;margin: 0;float: left;height: 40px;border-radius: 0;border-radius: 0;box-shadow: none;}
    .link{padding-bottom: 10px;}
}

.hdtop{position: fixed;right: 20px;bottom: -100px;transition: 1s;cursor: pointer;z-index: 99999;}






